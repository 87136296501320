@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'visby';
  src: url('../public/static/fonts/VisbyCF-Heavy.otf');
  font-weight: 800;
}

@font-face {
  font-family: 'visby';
  src: url('../public/static/fonts/VisbyCF-Bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'visby';
  src: url('../public/static/fonts/VisbyCF-DemiBold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'visby';
  src: url('../public/static/fonts/VisbyCF-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'visby';
  src: url('../public/static/fonts/VisbyCF-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'visby';
  src: url('../public/static/fonts/VisbyCF-Light.otf');
  font-weight: 300;
}

@layer base {
  html,
  body {
    @apply max-w-full font-ftf-visby bg-ftf-mine-shaft scroll-pt-24 scroll-smooth;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  .btn {
    @apply btn--base btn--pseudo bg-ftf-mine-shaft text-white;
  }

  .btn--la-palma {
    @apply btn--base btn--pseudo bg-ftf-la-palma text-white;
  }

  .btn--punch {
    @apply btn--base btn--pseudo bg-ftf-punch text-white;
  }

  .btn--mine-shaft {
    @apply btn--base btn--pseudo bg-ftf-mine-shaft text-white;
  }

  .btn--white--outline {
    @apply btn--base btn--pseudo border-2 border-white text-white;
  }

  .btn--mine-shaft--outline {
    @apply btn--base btn--pseudo border-2 border-ftf-mine-shaft text-ftf-mine-shaft [&:not([disabled])]:hover:text-white;
  }

  .label {
    @apply text-sm uppercase font-bold;
  }

  .tag--button {
    @apply hover:bg-ftf-mine-shafter hover:text-white transition-colors;
  }

  .tag {
    @apply rounded-full text-xs lg:text-sm bg-white text-ftf-mine-shaft px-3 lg:px-4 xl:px-6 py-[0.125rem] font-semibold flex-none;
  }

  .title {
    @apply text-[26px] sm:text-4xl lg:text-5xl font-black;
  }

  .sub--title {
    @apply text-base lg:text-lg font-black;
  }

  .section--detail {
    @apply section--detail--top section--detail--bottom;
  }

  .section {
    @apply section--top section--bottom;
  }

  .space--detail {
    @apply space--detail--top space--detail--bottom;
  }

  .space {
    @apply space--top space--bottom;
  }

  .content {
    @apply content--base content--pseudo content--utils content--paragraphs content--headings;
  }

  .content--recap {
    @apply content line-clamp-3 children-p:leading-6 children-p:font-medium text-sm h-[75px];
  }

  .detail--image {
    @apply rounded-2xl lg:rounded-3xl aspect-video w-full max-md:max-w-sm overflow-hidden;
  }
}

@layer utilities {
  .btn--pseudo {
    @apply transition-all [&:not([disabled])]:hover:bg-ftf-mine-shafter disabled:opacity-50 [&:not([disabled])]:focus:scale-95 [&:not([disabled])]:hover:scale-105;
  }

  .btn--base {
    @apply py-[10px] lg:px-6 px-5 rounded-full inline-flex items-center font-semibold text-center lg:tracking-wide;
  }

  .flex--center {
    @apply flex items-center justify-center;
  }

  .content--pseudo {
    @apply children-a:underline hover:children-a:text-ftf-mine-shafter;
  }

  .content--base {
    @apply last:[&>*]:mb-0 children-b:font-bold children-a:text-ftf-punch children-a:font-bold children-strong:font-bold;
  }

  .content--paragraphs {
    @apply children-p:leading-7 children-p:mb-6 children-p:font-light max-sm:children-p:text-sm max-sm:children-p:leading-6;
  }

  .content--utils {
    @apply children-ul:mb-6 children-ul:pl-8 children-ul:list-disc;
  }

  .content--headings {
    @apply children-headings:mb-4 children-h1:mb-6 children-h1:text-3xl children-h2:text-xl lg:children-h2:text-2xl lg:children-h2:font-black children-h3:text-xl children-headings:font-bold;
  }

  .box--shadow {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .box--detail--shadow {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  }

  .box--large--shadow {
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.31);
  }

  .box--rounded {
    @apply rounded-3xl lg:rounded-[40px];
  }

  .text--shadow {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .text--shadow--subtle {
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }

  .flip--horizontal {
    --tw-scale-x: -1;
    --tw-scale-y: -1;
  }

  .section--top {
    @apply pt-14 lg:pt-24;
  }

  .section--bottom {
    @apply pb-14 lg:pb-24;
  }

  .section--detail--top {
    @apply pt-10 lg:pt-16;
  }

  .section--detail--bottom {
    @apply pb-10 lg:pb-16;
  }

  .space--detail--top {
    @apply mt-6 lg:mt-10 xl:mt-14;
  }

  .space--detail--bottom {
    @apply mb-6 lg:mb-10 xl:mb-14;
  }

  .space--top {
    @apply mt-8 lg:mt-12 xl:mt-16;
  }

  .space--bottom {
    @apply mb-8 lg:mb-12 xl:mb-16;
  }

  .grid--responsive {
    @apply sm:grid flex max-sm:overflow-auto max-sm:-mr-4 max-sm:pr-4 no--scrollbar;
  }

  .no--scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no--scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .no--header {
    @apply pt-[100px] -mt-[100px] sm:pt-[125px] sm:-mt-[125px];
  }
}

/* WordPress helpers */

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}

/* Progress bar */

#nprogress .bar {
  @apply bg-ftf-punch;
}

#nprogress .spinner-icon {
  @apply hidden;
}

#nprogress .peg {
  @apply shadow;
}

/* Form helpers */

.form-duuf-submit > button {
  @apply btn--punch py-2 gap-2;
}

.form-duuf-group {
  @apply mb-5 last:mb-0;
}

.form-duuf-loading {
  @apply absolute inset-0 z-10 bg-white/50 flex--center;
}

.form-duuf-label {
  @apply block;
}

.form-duuf-error {
  @apply pl-6 text-xs text-ftf-punch font-medium mb-1;
}

.form-duuf-input,
.form-duuf-select__control {
  @apply rounded-full;
}

.form-duuf-input,
.form-duuf-textarea,
.form-duuf-select__control {
  @apply text-ftf-mine-shaft bg-ftf-seashell w-full h-11 px-6 flex items-center;
}

.form-duuf-textarea {
  @apply h-40 rounded-2xl pt-4;
}

.form-duuf-file input {
  @apply hidden;
}

.form-duuf-file-button button {
  @apply btn--punch py-1 px-2;
}

.form-duuf-checkbox-consent {
  @apply mr-3;
}

.form-duuf-group-consent {
  @apply text-sm;
}

.form-duuf-group-consent a {
  @apply text-ftf-punch font-bold underline;
}
